<template>
  <div>
    <b-form-group>
      <b-input-group>
        <b-input-group-prepend style="width: 160px">
          <b-input-group-text
            class="font-weight-bolder w-100 justify-content-center"
          >
            <span>{{ label }}</span>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input disabled :value="value"></b-form-input>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: ["label", "value"],
};
</script>

<style scoped>
.w-30 {
  min-width: 30%;
}
</style>